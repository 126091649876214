<template>
  <div class="space-y-5">

    <main v-if="is_API_loaded" class="bg-white rounded-2xl w-full pt-4">
      <section class="px-4 grid grid-cols-2 gap-2 lg:grid-cols-8">
        <vs-input class="lg:col-span-2" v-model="search_title" @blur="search" @keypress.enter="search" placeholder="搜尋商品/編號/條碼" />

        <vs-input type="date" v-model="start_date" label="銷售起" @blur="search" @keypress.enter="search" />
        <vs-input type="date" v-model="end_date" label="銷售訖" @blur="search" @keypress.enter="search" />
      </section>

      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min xl:w-full">
          <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="indeterminateHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 筆</span></vs-checkbox
          >

          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 筆</span>
            <span v-else class="text-xs">共 {{ productSalesList.length }} 筆</span>
          </vs-checkbox>

          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="title_section" key="1">
              <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="toPackingSlip" class="px-4 hover:bg-gray-background font-medium">列印揀貨單</button>
              </div>
            </div>
            <div v-else class="title_section" key="2">
              <span class="title ml-14">標題</span>
              <span class="section1">商品編號</span>
              <span class="section1">商品條碼</span>
              <span class="section1">商品規格</span>
              <span class="section1">庫存數</span>
              <span class="section1">銷售數量</span>
              <span class="section1">銷售金額</span>
              <span class="section1">銷售數量(已付)</span>
              <span class="section1">銷售金額(已付)</span>
            </div>
          </transition>
        </div>

        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min xl:w-full">
          <div v-for="item in productSalesList" :key="item.spsno">
            <router-link
              :to="{
                name: 'edit-product',
                params: {
                  id: item.spno,
                },
              }"
              class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative"
            >
              <vs-checkbox class="checkbox_section" @click.stop="checkBoxHandleClick" v-model="selected" :val="item.spsno"></vs-checkbox>
              <div class="title_section">
                <div class="w-14">
                  <div class="w-10 h-10 items-center justify-center flex border border-gray-border rounded-lg overflow-hidden">
                    <img v-if="item.img" :src="item.img" class="w-full h-full object-cover" />
                    <svg-icon v-else icon-class="product_tag" className="gray-border sm"></svg-icon>
                  </div>
                </div>
                <div class="flex flex-col title">
                  <span class="text-limit-1">{{ item.title }}</span>
                </div>
                <div class="section1">{{ item.sku }}</div>
                <div class="section1">{{ item.code }}</div>
                <div class="section1">{{ item.spec }}</div>
                <div v-if="item.stock_track == 1" class="section1">{{ item.snum | currency }}</div>
                <div v-else class="section1">未追蹤庫存</div>
                <div class="section1">{{ item.onums1 | currency }}</div>
                <div class="section1">NT${{ item.opays1 | currency }}</div>
                <div class="section1" v-if="item.onums1 == item.onums2">{{ item.onums2 | currency }}</div>
                <div class="section1" v-else style="color:red">{{ item.onums2 | currency }}</div> 
                <div class="section1" v-if="item.onums1 == item.onums2">NT${{ item.opays2 | currency }}</div>
                <div class="section1" v-else style="color:red">NT${{ item.opays2 | currency }}</div>
              </div>

              <!-- 預覽分頁按鈕 -->
              <a
                @click.stop=""
                :href="item.mpages"
                target="_blank"
                class="
                  hidden
                  lg:block
                  absolute
                  right-3
                  top-1/2
                  transform
                  -translate-y-1/2
                  transition-all
                  opacity-0
                  hover:bg-gray-divide
                  group-hover:opacity-100 group-hover:visible
                  invisible
                  rounded-lg
                "
              >
                <svg-icon icon-class="eye_view" className="primary xl"></svg-icon>
              </a>
            </router-link>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>
    
    <!-- pagination -->
    <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="current_page" :length="pagination.tpages" />
      <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
    </div>

  </div>
</template>

<script>
export default {
  name: 'report_products',
  data() {
    return {
      is_API_loaded: false,
      current_page: 1, // 當前頁數
      search_title: '', // 搜尋標題input
      start_date: '',
      end_date: '',
      filtered: [], // 篩選發布狀態
      productSalesList: [], // 商品列表
      pagination: [], // 頁數資訊
      selected: [], // 多選checkbox
      selectAll: false,
      indeterminate: false,
      defaultQuery: {}, // 預設$route.query
    }
  },
  computed: {
    _collections() {
      let flatArr = []
      this.collections.forEach((arr1) => {
        flatArr.push({
          sptno: arr1.sptno,
          title: arr1.title,
        })

        arr1.arr.forEach((arr2) => {
          flatArr.push({
            sptno: arr2.sptno,
            title: arr2.title,
          })

          arr2.arr.forEach((arr3) => {
            flatArr.push({
              sptno: arr3.sptno,
              title: arr3.title,
            })
          })
        })
      })

      return flatArr
    },
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      return this.selected.toString()
    },
  },
  async created() {
    let query = this.$route.query;
    this.search_title = query.search_title
    this.start_date = query.start_date
    this.end_date = query.end_date
    this.current_page = query.page ? parseInt(query.page) : 1
    this.resetRouteParam() //重設route參數
    const res = await Promise.all([this.getproductSalesList()])
    // res會是陣列，若都為true，才顯示頁面
    if (res.every((e) => e === true)) {
      this.is_API_loaded = true
    }
  },
  methods: {
    // 改變頁籤時觸發
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.getproductSalesList(['page', this.current_page])
    },
    // 篩選標題時觸發
    search(e) {
      this.current_page = 1
      if(this.start_date != '' && this.end_date == '') return ;
      else{
        if(this.start_date > this.end_date){
          let date = this.start_date;
          this.start_date = this.end_date;
          this.end_date = date;
        }
        this.resetRouteParam() //重設route參數
        this.getproductSalesList()
      } 
    },
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.selected.length > 0 && this.selected.length < this.productSalesList.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === this.productSalesList.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.productSalesList.length; i++) {
          this.selected.push(this.productSalesList[i].spsno)
        }
      } else {
        this.selected = []
      }
    },
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
    // 3.1.1.1 商品列表-讀取
    getproductSalesList() {
      return this.$axios({
        url: 'front/store/report/getProductSalesList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: this.current_page,
          title: this.search_title,
          start_date: this.start_date,
          end_date: this.end_date,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.productSalesList = res.data.Data
          this.pagination = res.data.Page
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
    //重設route參數
    resetRouteParam() {
      let replaceQuery = [];
      if(this.search_title != "") replaceQuery['search_title'] = this.search_title;
      if(this.start_date != "") replaceQuery['start_date'] = this.start_date;
      if(this.end_date != "") replaceQuery['end_date'] = this.end_date;
      if(this.current_page != "") replaceQuery['current_page'] = this.current_page;
      this.$router.replace({ query: replaceQuery })
    },
    // 開啟揀貨單
    toPackingSlip() {
      let val = '';
      for(let i=0; i<this.selected.length; i++){
        if(val == '') val = this.selected[0];
        else val += ',' + this.selected[i];
      }
      let routeData = this.$router.resolve({ name: 'report_products_slip', query: { spsnos:  val, start_date:  this.start_date, end_date:  this.end_date} })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min xl:w-full;
  .title {
    @apply w-48 sm:flex-grow;
  }
}

.section1 {
  width: 105px;
}

.section2 {
  width: 144px;
}
</style>
